.page {
    background: #efefef;
    height: calc(100% - var(--header_height));
}

.container {
    width: 80%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.preview {
    width: 700px;
    height: 525px;
}

p {
    font-size: 20px;
    font-weight: bold;
}

@media (max-width: 768px), (min-width: 769px) and (max-width: 1024px) {
    .container {
        width: 80%;
        height: 100%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        text-align: center;
    }

    .preview {
        width: 100%;
        height: 100%;
    }

    .p {
        font-size: 14px;
    }
}