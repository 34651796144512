.header {
    height: 80px;
    background-color: black;
}

.container {
    width: 80%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #2d333d;
    flex-wrap: wrap;
}

.logo {
    width: 180px;
    height: 75px;
    grid-area: logo;
}

.phone {
    grid-area: phone;
    width: 180px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #FFFFFF;
}

.phone img {
    width: 40px;
    height: 40px;
}

.phoneNumbers p {
    font-size: 14px;
    margin: 0;
}

.phoneNumbers p:hover {
    text-decoration: underline;
}

.burgerMenuIcon {
    width: 14px;
    height: 14px;
    color: #FFFFFF;
    grid-area: menu;
}

@media (max-width: 768px), (min-width: 769px) and (max-width: 1024px) {
    .header {
        height: 100%
    }
    .container {
        display: grid;
        grid-template-columns: 80px auto 85px;
        grid-template-areas: 'menu logo phone';
    }
    .logo {
        width: 80px;
        height: 35px;
        align-self: center;
    }
    .phone {
        width: 85px;
    }
    .phone img {
        width: 13px;
        height: 13px;
    }
    .phone p {
        font-size: 7px;
    }
}