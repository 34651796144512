.nav {
    width: 400px;
    display: flex;
    justify-content: space-between;
}

.nav a {
    color: #FFF;
    text-decoration: none;
}

.nav a:hover {
    color: #f0c659;
}

.nav a.active {
    color: #f0c659;
    font-weight: bold;
}

@media (max-width: 768px), (min-width: 769px) and (max-width: 1024px) {
    .nav {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        font-size: 14px;
        margin: 25px 35px 25px 35px;
    }

    .nav a {
        text-decoration: none;
        color: white;
    }

    .nav a:hover {
        color: gold;
    }

    .nav a.active {
        text-decoration: underline;
        color: gold;
        margin: 5px 10px 5px 10px;
    }
}